import {
  Box,
  Button,
  Dialog,
  Group,
  Image,
  MantineProvider,
  Modal,
  Progress,
  Text
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import Cookies from 'js-cookie';
import React, { createContext, useContext, useEffect, useMemo } from "react";
import CookieConsent from "react-cookie-consent";
import ReactGA from 'react-ga';
import { useLocation, useNavigate } from "react-router-dom";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { Socket, connect } from "socket.io-client";
import usePersistState from "../hooks/usePersistState";
import ChoosePlan from "../pages/choose_plan";
import PublicPage from "../pages/public";
import Recharge from "../pages/recharge";
import { GET_COMPANY_INTEGRATION } from "../services/companies";
import { GET_ALL_USERS } from "../services/users";
import { useQuery } from "../utility/util";
import { useAuth } from "./auth.context";
import { useLanguage } from "./language.context";

interface ConfirmDialogProps {
  (
    props: { text: string; buttonText?: string },
    callback: (props: { confirmed: boolean }) => void
  ): void;
}
interface ConfigsProps {
  primary?: string; navbar?: string; image?: string; icon?: string;
  contrast?: string; brand?: string;
  name?: string;
}
interface AppContextProps {
  confirmDialog: ConfirmDialogProps;
  setColorScheme: (colorScheme: "dark" | "light") => void;
  socket: Socket;
  configs: ConfigsProps;
  mobileApp?: any;
  setConfigs: (vl: ConfigsProps) => any;
  registration?: ServiceWorkerRegistration;
  agents: any[];
  loadAgents: () => void;
  openedSidebar?: boolean;
  toggleOpenedSidebar?: () => void;
  expandImage: (url: string) => void;
  expandText: (text: string) => void;
  newOrder: any;
  agentRoles: any[];
  agentLanguages: any[];
  setNewOrder: (params: any) => void;
  notifyGaEvent: (params: any) => void;
  startPlan: (start?: boolean) => void;
  recharge: (start?: boolean) => void;
}

const AppContext = createContext<AppContextProps>({} as AppContextProps);

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const lsColorScheme = localStorage.getItem("@awaz/colorScheme") as
    | "light"
    | "dark";

  const [openedConfirm, setOpenedConfirm] = React.useState(null);
  const [newOrder, setNewOrder] = React.useState(null);
  const [startingPlan, setStartingPlan] = React.useState(false);
  const [recharge, setRecharge] = React.useState(false);
  const [importingFiles, setImportingFiles] = React.useState([]);
  const [agents, setAgents] = React.useState<any[]>([]);
  const [configs, setConfigs] = React.useState<ConfigsProps>({});
  const [openImage, setOpenImage] = React.useState<string>("");
  const [openText, setOpenText] = React.useState<string>("");
  const [mobileApp, setMobileApp] = React.useState<any>({});
  const [colorScheme, setColorScheme] = React.useState<"dark" | "light">(
    lsColorScheme || "light"
  );
  const [registration, setRegistration] =
    React.useState<ServiceWorkerRegistration>(null);
  
  const query = useQuery();

  let fbc = query.get("fbclid");

  const [socket, setSocket] = React.useState<Socket>(null);

  const { role, user: currentUser, companyHost, loadUserData, user, userData } = useAuth();
  const { str } = useLanguage();

  const { pathname } = useLocation();
  const navigate = useNavigate();


  const gaTrackings = [
    "G-LY81V86E4J",
    companyHost?.ga,
  ].filter(nn => nn);

  const [openedSidebar, setOpenedSidebar] = usePersistState<boolean>(
    "@awaz/opened-sidebar",
    false
  );

  const loadAgents = (setLoading = true) => {
    if (role) {
      GET_ALL_USERS()
        .then((dt) => {
          setAgents(
            dt
            .filter(u => u?.profile?.title !== "Client")
            .sort((a, b) => (a.name > b.name ? 1 : -1))
          );
        })
        .catch((err) => {
          notifications.show({
            title: "Ops",
            message: err.message,
            color: "red",
          });
        });
    }
  };

  const confirmDialog: ConfirmDialogProps = (
    { text, buttonText = str("CONFIRM") },
    callback
  ) => {
    setOpenedConfirm({ text, buttonText, callback });
  };

  const toggleOpenedSidebar = () => {
    setOpenedSidebar(!openedSidebar);
  };

  const notifyGaEvent = (params: any) => {
    for(let trackingId of gaTrackings){
      ReactGA.event(params, trackingId);
    }
  }

  useEffect(() => {
    GET_COMPANY_INTEGRATION("mobile-app")
    .then((res) => { setMobileApp(res) })
    .catch(err => {})
  }, [userData])

  useEffect(() => {
    for(let trackingId of gaTrackings){
      ReactGA.initialize(trackingId);
      ReactGA.set({ debug: false });
    }
  }, [JSON.stringify(gaTrackings)]);
  
  useEffect(() => {
    for(let trackingId of gaTrackings){
      ReactGA.pageview(pathname, [trackingId]);
    }
  }, [JSON.stringify(gaTrackings), pathname]);

  // useEffect(() => {
  //   if ("serviceWorker" in navigator) {
  //     navigator.serviceWorker
  //       .register("/service-worker.js")
  //       .then((registration) => {
  //         setRegistration(registration);
  //       })
  //       .catch((error) => {
  //         console.error("Service worker registration failed:", error);
  //       });
  //   }
  // }, []);


  useEffect(() => {
    if(fbc) Cookies.set("fbc", fbc);
  }, [fbc])

  useEffect(() => {
  }, [socket]);

  useEffect(() => {
    localStorage.setItem("@awaz/colorScheme", colorScheme);
  }, [colorScheme]);

  useEffect(() => {
    if (currentUser) {
      const s = connect(process.env.REACT_APP_SERVER_URL, {
        extraHeaders: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      });
      setSocket(s);
    }
  }, [currentUser]);

  useEffect(loadAgents, [role]);

  useEffect(() => {
    setConfigs(c => ({
      ...c,
      name: userData?.company?.name,
      primary: companyHost?.appearance?.colors?.primary || userData?.company?.appearance?.colors?.primary || "#FF4D17",
      navbar: companyHost?.appearance?.colors?.navbar || userData?.company?.appearance?.colors?.navbar || "white",
      image: companyHost?.image ?? userData?.company?.image,
      icon: companyHost?.icon ?? userData?.company?.icon,
    }))
  }, [companyHost, userData])

  const value = useMemo<AppContextProps>(() => {
    return {
      confirmDialog,
      setColorScheme,
      socket,
      mobileApp,
      registration,
      agents,
      openedSidebar,
      loadAgents,
      toggleOpenedSidebar,
      expandImage: (url) => setOpenImage(url),
      expandText: (text) => setOpenText(text),
      newOrder,
      setNewOrder,
      notifyGaEvent,
      agentRoles: [
        { value: "sales", label: str("AGENTS.ROLES.SALES") },
        { value: "customer-support", label: str("AGENTS.ROLES.CUSTOMER_SUPPORT") },
        { value: "other", label: str("AGENTS.ROLES.OTHER") },
      ],
      agentLanguages: [
        { "label": "English - United States", "value": "en-US" },
        { "label": "English - India", "value": "en-IN" },
        { "label": "English - United Kingdom", "value": "en-GB" },
        { "label": "German - Germany", "value": "de-DE" },
        { "label": "Spanish - Spain", "value": "es-ES" },
        { "label": "Spanish - Latin America", "value": "es-419" },
        { "label": "Hindi - India", "value": "hi-IN" },
        { "label": "Japanese - Japan", "value": "ja-JP" },
        { "label": "Portuguese - Portugal", "value": "pt-PT" },
        { "label": "Portuguese - Brazil", "value": "pt-BR" },
        { "label": "French - France", "value": "fr-FR" },
        { "label": "Chinese - China", "value": "zh-CN" },
        { "label": "Russian - Russia", "value": "ru-RU" },
        { "label": "Italian - Italy", "value": "it-IT" },
        { "label": "Korean - Korea", "value": "ko-KR" },
        { "label": "Dutch - Netherlands", "value": "nl-NL" },
        { "label": "Polish - Poland", "value": "pl-PL" },
        { "label": "Turkish - Turkey", "value": "tr-TR" },
        { "label": "Vietnamese - Vietnam", "value": "vi-VN" },
        { "label": "Multilingual (en and es)", "value": "multi" }
      ],
      // agentLanguages: [
      //   { value: "ar", label: "Arabic" },
      //   { value: "bg", label: "Bulgarian" },
      //   { value: "zh", label: "Chinese" },
      //   { value: "hr", label: "Croatian" },
      //   { value: "cs", label: "Czech" },
      //   { value: "da", label: "Danish" },
      //   { value: "nl", label: "Dutch" },
      //   { value: "en", label: "English" },
      //   { value: "fi", label: "Finnish" },
      //   { value: "fr", label: "French" },
      //   { value: "de", label: "German" },
      //   { value: "el", label: "Greek" },
      //   { value: "hi", label: "Hindi" },
      //   { value: "hu", label: "Hungarian" },
      //   { value: "id", label: "Indonesian" },
      //   { value: "it", label: "Italian" },
      //   { value: "ja", label: "Japanese" },
      //   { value: "ko", label: "Korean" },
      //   { value: "ms", label: "Malay" },
      //   { value: "no", label: "Norwegian" },
      //   { value: "pl", label: "Polish" },
      //   { value: "pt", label: "Portuguese" },
      //   { value: "ro", label: "Romanian" },
      //   { value: "ru", label: "Russian" },
      //   { value: "sk", label: "Slovak" },
      //   { value: "es", label: "Spanish" },
      //   { value: "sv", label: "Swedish" },
      //   { value: "ta", label: "Tamil" },
      //   { value: "tr", label: "Turkish" },
      //   { value: "uk", label: "Ukrainian" },
      //   { value: "vi", label: "Vietnamese" },
      // ],
      startPlan: (start = true) => {
        setStartingPlan(start);
        if(!start) loadUserData();
      },
      recharge: (start = true) => {
        setRecharge(start);
        if(!start) loadUserData();
      },
      configs: {
        ...configs,
        brand: {
          "black": "#000000",
          "green": "#00DB72",
          "yellow": "#FFD540",
          "violet": "#FF4D17",
          "white": "#FBFBFB",
          "blue": "#0A39FC",
          "pink": "#FC0A6B",
        }[configs.navbar ?? "white"],
        contrast: {
          "black": "#FBFBFB",
          "green": "#000000",
          "yellow": "#000000",
          "violet": "#FBFBFB",
          "blue": "#FBFBFB",
          "pink": "#FBFBFB",
          "white": "#000000",
        }[configs.navbar ?? "white"],
      },
      setConfigs: (c) => {
        setConfigs(v => ({...v, ...c}));
      },
    };
  }, [
    configs,
    setConfigs,
    confirmDialog,
    setColorScheme,
    socket,
    newOrder,
    registration,
    agents,
    openedSidebar,
    toggleOpenedSidebar,
  ]);

  return (
    <MantineProvider
      withCssVariables
      theme={{
        fontFamily: "Inter, sans-serif",
        headings: { fontWeight: "bold" },
        primaryColor: "violet",
        colors: {
          gray: [ "#8a8a8a", "#8a8a8a", "#8a8a8a", "#8a8a8a", "#CCCCEC", "#8a8a8a", "#8a8a8a", "#8a8a8a", "#CCCCEC", "#8a8a8a" ],
          dark: [
            '#FAFAFA',
            '#acaebf',
            '#8c8fa3',
            '#666980',
            '#242442',
            '#242442',
            '#2b2c3d',
            '#3C3C5D',
            '#0c0d21',
            '#01010a',
          ],
        },
        components: {
          Table: { styles: {
            root: {
              border: '1px solid #242442',
              borderRadius: 15,
            },
            th: {
              backgroundColor: "#242442",
              padding: '20px 10px',
              color: '#B3B3F2',
              textAlign: 'center',
            },
            td: {
              backgroundColor: "#3C3C5D",
              padding: '10px 10px',
            },
          }},
          // TextInput: { styles: { input: { color: 'white', backgroundColor: '#242442' } } },
          // Textarea: { styles: { input: { color: 'white', backgroundColor: '#242442' } } },
          // Select: { styles: { input: { color: 'white', backgroundColor: '#242442' } } },
          // PasswordInput: { styles: { input: { color: 'white', backgroundColor: '#242442' } } },
          // Box: { styles: { input: { color: 'white', backgroundColor: '#242442' } } },
          Paper: { styles: { root: { borderRadius: '15px' } } },
        },
      }}
      defaultColorScheme="dark"
      // defaultColorScheme="light"
      // forceColorScheme="dark"
    >
      <AppContext.Provider value={value}>
        <>
          <>
            <Dialog
              opened={Boolean(openedConfirm)}
              withCloseButton={false}
              onClose={() => setOpenedConfirm(null)}
              size="lg"
              radius="md"
              zIndex={9999}
            >
              <Text size="sm" mb="sm">
                {openedConfirm?.text}
              </Text>

              <Group align="flex-end">
                <Button variant="outline" color="gray"
                  onClick={() => setOpenedConfirm(null)}
                >{str("CANCEL")}</Button>
                <Button
                  color="violet"
                  onClick={() => {
                    openedConfirm?.callback &&
                      openedConfirm?.callback({ confirmed: true });
                    setOpenedConfirm(null);
                  }}
                >
                  {openedConfirm?.buttonText}
                </Button>
              </Group>
            </Dialog>
          </>
        </>
        {(companyHost && !companyHost.active) ? <PublicPage /> : children}
        {/* <Helmet>
          {configs.name && <title>{configs.name}</title>}
          {configs.icon
            ? <link rel="icon" href={configs.icon} />
            : <link rel="icon" href={"/icon.png"} />}
        </Helmet> */}
        {/* {userData?.user && role?.profile?.title !== "Client" && process.env.REACT_APP_SUPORT_BOARD_ID && <Helmet>
          <script id="chat-init" src={`https://cloud.board.support/account/js/init.js?id=${process.env.REACT_APP_SUPORT_BOARD_ID}&name=${userData.user.name}`}></script>
        </Helmet>} */}
        <Dialog opened={importingFiles.length > 0}>
          {importingFiles.map((imp) => (
            <div>
              <div style={{ textAlign: "right" }}>
                <Text c="violet" size="sm">
                  {imp.done}/{imp.total}
                </Text>
              </div>
              <Progress color="violet" value={imp.percent} />
              <Text size="xs" style={{ textAlign: "right" }}>
                {{ themes: "Importação de pautas" }[imp.type]} -{" "}
                {imp.origin.toUpperCase()}
              </Text>
            </div>
          ))}
        </Dialog>
        <Modal
          opened={!!openImage}
          onClose={() => setOpenImage(null)}
          fullScreen
          size="xl"
        >{openImage && <TransformWrapper
          limitToBounds
        >
          {/* {({ zoomIn, zoomOut, resetTransform, ...rest }) => ( */}
            <TransformComponent>
              <Image
                // onClick={() => zoomIn()}
                src={openImage} height={"90vh"} width="100%" fit="contain" />
            </TransformComponent>
          {/* )} */}
        </TransformWrapper>}</Modal>
        <Modal
          opened={!!openText}
          onClose={() => setOpenText(null)}
          size="md"
        ><Box style={{border: 10, background: "#F4F4F4"}} p="lg">
          {openText}
        </Box>
        </Modal>
        <Modal
          opened={startingPlan}
          overlayProps={{ backgroundOpacity: 0.5 }}
          onClose={() => setStartingPlan(false)}
          size="90vw"
          withCloseButton={false}
          styles={{content: {background: '#242442'}}}
        >
          <ChoosePlan onClose={() => setStartingPlan(false)} />
        </Modal>
        <Modal
          opened={recharge}
          overlayProps={{ backgroundOpacity: 0.5 }}
          onClose={() => setRecharge(false)}
          size="90vw"
          withCloseButton={false}
          styles={{content: {background: '#242442'}}}
        >
          <Recharge onClose={() => setRecharge(false)} />
        </Modal>
        <CookieConsent
          style={{
            background: configs.primary ?? '#12141d', fontWeight: 'bold'
          }}
          buttonStyle={{
            color: "white",
            background: 'transparent',
            fontWeight: "bold",
            border: '2px solid white',
            borderRadius: 8,
            padding: '5px 30px'
          }}
        >
          {str("COOKIES_DESCRIPTION")}{" "}
        </CookieConsent>
      </AppContext.Provider>
    </MantineProvider>
  );
};

export const useApp = () => useContext(AppContext);

export const useAgentVariables = () => {
  const [companyVariables, setCompanyVariables] = React.useState([]);

  const { userData } = useAuth();

  useEffect(() => {
    setCompanyVariables([
      { label: "Current Time", value: "CurrentTime" },
      { label: "Contact Name", value: "Contact.Name" },
      { label: "Contact First Name", value: "Contact.FirstName" },
      { label: "Contact Phone", value: "Contact.Phone" },
      { label: "Contact E-mail", value: "Contact.Email" },
      ...(userData?.company?.fields ?? []).map(f => ({ label: `Contact.${f.title}`, value: `Contact.${(f.title ?? "").replace(/ /g, "")}` }))
    ])
  }, [userData?.company?.fields]);

  return companyVariables;
};
