import {
    Box,
    Grid
} from "@mantine/core";
import React from "react";
import InputField, { InputFieldTypes } from "../input_field";
import { useLanguage } from "../../contexts/language.context";
import { useAgentVariables, useApp } from "../../contexts/app.context";

export default function Settings({ data, voices, setData, setVoice }){
    const { str } = useLanguage();
    const { agentRoles, agentLanguages } = useApp();
    const companyVariables = useAgentVariables();

    return <Box p="md">
        <Grid>
        <Grid.Col span={{base: 12, md: 6}}>
            <InputField
            name="title"
            title={str("AGENTS.AGENT_NAME")}
            value={data?.title}
            onChange={({title}) => setData(na => ({...na, title}))}
            />
        </Grid.Col>
        <Grid.Col span={{base: 12, md: 6}}>
            <InputField
            name="voice_id"
            title={str("AGENTS.SELECT_VOICE")}
            onClick={() => setVoice({...data?.data?.voice})}
            value={voices.find(rv => rv.voice_id === data?.data?.retell?.agent?.voice_id)?.voice_name}
            readOnly
            />
        </Grid.Col>
        <Grid.Col span={{base: 12, md: 4}}>
            <InputField
            name="language"
            title={str("LANGUAGE")}
            value={(data?.data?.retell?.agent?.language ?? "en-US")}
            fieldType={InputFieldTypes.SELECT}
            clearable={false}
            onChange={({language}) => setData(na => ({...na, data: { ...na.data, retell: {
                ...na?.data?.retell, agent: { ...na?.data?.retell?.agent, language } } } }))}
            options={agentLanguages}
            />
        </Grid.Col>
        <Grid.Col span={{base: 12, md: 4}}>
            <InputField
                name="role"
                title={str("AGENTS.ROLE")}
                placeholder={str("AGENTS.ROLE_PLACEHOLDER")}
                value={data?.data?.role}
                onChange={({role}) => setData(na => ({...na, data: { ...na.data, role } }))}
            />
        </Grid.Col>
        <Grid.Col span={{base: 12, md: 4}}>
            <InputField
            name="ambient_sound"
            title={str("AGENTS.AMBIENT_SOUND")}
            value={data?.data?.retell?.agent?.ambient_sound}
            fieldType={InputFieldTypes.SELECT}
            clearable={true}
            searchable={false}
            onChange={({ambient_sound}) => setData(na => ({...na, data: { ...na.data, retell: {
                ...na?.data?.retell, agent: { ...na?.data?.retell?.agent, ambient_sound } } } }))}
            options={[
                { label: str("COFFEE_SHOP"), value: "coffee-shop" },
                { label: str("CONVENTION_HALL"), value: "convention-hall" },
                { label: str("SUMMER_OUTDOOR"), value: "summer-outdoor" },
                { label: str("MOUNTAIN_OUTDOOR"), value: "mountain-outdoor" },
                { label: str("STATIC_NOISE"), value: "static-noise" },
            ]}
            />
        </Grid.Col>
        <Grid.Col span={{base: 12, md: 6}}>
            <InputField
                name="company_info"
                title={str("AGENTS.COMPANY_INFO_TITLE")}
                description={str("AGENTS.COMPANY_INFO_DESCRIPTION")}
                placeholder={str("AGENTS.COMPANY_INFO_PLACEHOLDER")}
                options={companyVariables}
                value={data?.data?.company_info}
                fieldType={InputFieldTypes.TEXTAREA}
                minRows={8}
                maxRows={8}
                maxLength={1000}
                onChange={({company_info}) => setData(na => ({...na, data: { ...na.data, company_info }}))}
            />
        </Grid.Col>
        <Grid.Col span={{base: 12, md: 6}}>
            <InputField
            name="objective"
            title={str("AGENTS.OBJECTIVE_TITLE")}
            description={str("AGENTS.OBJECTIVE_DESCRIPTION")}
            options={companyVariables}
            maxLength={1000}
            placeholder={str("AGENTS.OBJECTIVE_PLACEHOLDER")}
            value={data?.data?.objective}
            fieldType={InputFieldTypes.TEXTAREA}
            minRows={8}
            maxRows={8}
            onChange={({objective}) => setData(na => ({...na, data: { ...na.data, objective }}))}
            />
        </Grid.Col>
        </Grid>
    </Box>
}